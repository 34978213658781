.btn-activate-year {
    right: 1.25rem;
    top: 0.5rem;
}


.calandar-widget table.calendar-content td, .calandar-widget table.calendar-content th {
    text-align: center;
    background-color: #fff;
    border-top: 1px solid #c8c9ca;
    border-right: 1px solid #d0d0d2;
    border-bottom: 1px solid #d0d0d2;
    height: 35px;
    width: 35px;
    padding: 5px;
}

.calandar-widget table.calendar-content td.department {
    background: #d5d5d5;
    font-weight: 700;
    height: 20px;
}

.calandar-widget table.calendar-content td.employee {
    text-align: left;
    min-width: 150px;
}


.calandar-widget table.calendar-content td.weekend,
.calandar-widget table.calendar-content td.day_off {
    background: #f0f0f0 !important;
}


.calandar-widget table.calendar-content td.legal_holiday {
    background: #c69af3 !important;;
}

.calandar-widget table.calendar-content td.vacation.requested_approved {
    background: #4dbd74;
    font-weight: bold;
}

.calandar-widget table.calendar-content td.vacation_lock:not(.weekend) {
    background: #ffccc9 !important;
    font-weight: bold !important;
    color: #c11f1f !important;
}

.calandar-widget table.calendar-content td.vacation.requested_new {
    background: #eafb2f;
    font-weight: bold;
}

.calandar-widget table.calendar-content td.duty_leave {
    background: #e0f4fe !important;
}

.calandar-widget table.calendar-content tr.current-employee td.employee,
.calandar-widget table.calendar-content tr.current-employee td.current-request {
    background: #ffeb3b !important;;
}


.calandar-widget.details table.calendar-content td.requested_canceled,
.calandar-widget.details table.calendar-content td.requested_rejected{
    background: #ff5e00 !important;
}

